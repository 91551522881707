import React from "react"

import { PageLayout } from "@components/layouts"
import Seo from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <Seo
        title="Podcast Production in Atlanta: Turning experience into content"
        ogDescription="Websuasion crafts engaging podcasts for leaders, turning deep conversations into content that builds authority and connections."
        image="/images/video/podcast-production-atlanta-ga.webp"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h1>Podcast Production</h1>
						<p>
							Our Atlanta-based production facility provides podcast production services for entrepreneurs and executives. Available in both audio and video formats, it solves the biggest marketing challenge. Content. Podcasting creates the cost-effective, unique, branded content your marketing team requires. The conversational format holds the listener's attention. That is a rare quality in our short-attention-span world. You get the luxury of time to cover what you do. You build authority in your industry. You garner the trust of ideal clients.
						</p>
						<h2>The Flexibility of Podcast Production</h2>
						<p>
							Podcast production is a flexible format. Episodes can be of any length and are only limited by the attention of your audience. Some podcasts run 30 minutes per episode. Others can run several hours. You have as much time as you need to cover a topic or dig deep into a conversation.
						</p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/video/podcast-production-atlanta-ga.webp" className="rounded-image" alt="Podcast Production in Atlanta - Websuasion" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
						<h2>The Problem with Wide Content</h2>
						<p>
							Most of what you find coming out of marketing departments and firms is wide content. Marketers go wide because it is easy to outsource such work. With a little research, a good copywriter can produce decent wide content. But when everyone uses the same technique, the culmination is noise. Your target audience finds little value in the content you post, so they ignore it.
						</p>
          </div>
        }
      />
	    <PageWrapper color="black">
	      <SingleColumnBlock
	        textPadded
	        text={
	            <div className="double-frame-block">
								<h2>The Benefit of Deep Content</h2>
								<p>
									Podcast production offers a format that encourages deep content. This is a chance for you to differentiate yourself in your industry. You are able to show your experience and knowledge. Your listeners join you on a conversational journey. It's an intellectual pursuit that dives deeper than the typical marketing message. Listeners receive high value, and they appreciate you for it.
								</p>
								<FormWrapper
			            modal
			            buttonProps={{
			              content: "Schedule An Introductory Call",
			              size: "tiny",
			              icon: "calendar alternate outline",
			            }}
			          >
			            <RequestMoreInfo />
			          </FormWrapper>
	            </div>
	        }
	      />
	    </PageWrapper>
      <TextBlock
        textPadded
        textLeft={
		      <div>
						<h2>Gaining Authority Through Podcast Production</h2>
						<p>
							Look at the professionals in your industry with the greatest authority. They are also the most successful. Through consistent podcast activity, listeners see you as an authority in your field. If your industry is complex, it allows you to be the one who makes sense of it all for those following your episodes. We now work in a knowledge economy.
						</p>
						<h2>Podcasting Production To Increase Networking Leverage</h2>
						<p>
							A key to successful business development is participation in networking groups. Chambers of Commerce, referral networking groups, and industry associations help you to make key connections. But, it can be difficult to gain recognition in those groups. It's a lot easier when members recognize you from your podcasting efforts. They are more likely to understand what you do and which clients are best to refer to you. And your podcast can provide an incentive for fellow members to connect with you.
						</p>
		      </div>
        }
        textRight={
					<div>
	          <img src="/images/video/business-development-through-podcasting.webp" className="rounded-image" alt="Podcasting Production To Increase Networking Leverage" />
	        </div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Business Development Through Podcasting</h2>
							<p>
								You gain access to high-value clients through podcast production compared to cold calling and advertising. Business owners who subscribe to podcasts have the desire to learn. They want to stay informed on emerging trends that impact their business. Your listeners run successful businesses with the budgets you hope to target. When your podcast appears in their feed weekly it keeps you top of mind. When they need what you do, you will be the first place they turn for the solution.
							</p>
							<h2>A Warm Connection</h2>
							<p>
								The most successful podcast production includes an interview segment. People react to conversation and story. These conversations are a tool for business development. Starting a conversation with key individuals at the companies you target is hard. Cold-calling or cold-emailing prospects with a sales pitch is not pleasant for anyone. Instead, your podcast can be the reason for connecting. You can invite them to appear on your podcast to talk about their company. Appeal to their need for brand publicity and business development. This is a warm connection. They will respond with appreciation and reciprocity. And sales.
	            </p>
	          </div>
	        }
	      />
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="team-block">
            <h3><a href="/atlanta-video-production/">YouTube Series Production</a></h3>
            <p>
              Boost your brand with long format video production. Websuasion creates engaging, optimized YouTube content that elevates visibility and credibility, maximizing ROI.
            </p>
          </div>
        }
        textCenter={
          <div className="team-block">
            <h3><a href="/atlanta-video-production/digital-course-creation/">Digital Course Creation</a></h3>
            <p>
              Transform expertise into new income streams with our digital course creation service. Maximize reach and efficiency, from concept to global client engagement.
            </p>
          </div>
        }
        textRight={
          <div className="team-block">
            <h3><a href="/atlanta-video-production/webinar-production/">Evergreen Webinars</a></h3>
            <p>
              Discover the power of evergreen webinars to educate, engage, and convert prospects to customers. Enable sales at scale without geographical and time limitations.
            </p>
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
